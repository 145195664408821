import axios from 'axios'
import { getDiscount, getCategoryNameByUrl } from './product-helper'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'
import { getInlineBannersData } from '@/lib/contentful/inlineBanners'

let ssSessionIdNamespace = Cookies.get('ssSessionIdNamespace') || uuidv4()
let ssUserId = Cookies.get('ssUserId') || uuidv4()
let ssPageLoadIdNamespace = uuidv4()

var config = {
  method: 'get',
  url: process.env.NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL,
  headers: {
    'searchspring-session-id': ssSessionIdNamespace,
    'searchspring-user-id': ssUserId,
    'searchspring-page-load-id': ssPageLoadIdNamespace,
  },
}

var contentFeedConfig = {
  method: 'get',
  url: process.env.NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL,
  headers: {
    'searchspring-session-id': ssSessionIdNamespace,
    'searchspring-user-id': ssUserId,
    'searchspring-page-load-id': ssPageLoadIdNamespace,
  },
}

var suggestionConfig = {
  method: 'get',
  url: `https://suggest-cache.searchspring.net/api/suggest/query?siteId=${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}`,
  headers: {
    'Content-Type': 'application/json',
    'searchspring-session-id': ssSessionIdNamespace,
    'searchspring-user-id': ssUserId,
    'searchspring-page-load-id': ssPageLoadIdNamespace,
  },
}

var contentRecsConfig = {
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    'searchspring-session-id': ssSessionIdNamespace,
    'searchspring-user-id': ssUserId,
    'searchspring-page-load-id': ssPageLoadIdNamespace,
  },
}

var trendingConfig = {
  method: 'post',
  url: process.env.NEXT_PUBLIC_TRENDING_URL,
  headers: {
    'Content-Type': 'application/json',
    'searchspring-session-id': ssSessionIdNamespace,
    'searchspring-user-id': ssUserId,
    'searchspring-page-load-id': ssPageLoadIdNamespace,
  },
  data: {
    siteId: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID,
    limit: 4,
  },
}

const getPageLoadId = () => {
  let dataLayerObj = window.dataLayer || []
  const mostRecentEvent = [...dataLayerObj]
    .reverse()
    .find((event) => event.event === 'page_view' || event.event === 'virtual_page_view')
  if (mostRecentEvent) {
    let lastPageUrl = mostRecentEvent.page_location
    let currentPageUrl = window.location.href
    if (lastPageUrl != currentPageUrl) {
      return uuidv4()
    }
  }
  return ssPageLoadIdNamespace
}

export const getSearchSpringData = async (url = null, query) => {
  if (query) {
    url = url + `&q=${query}`
  }
  if (url != null) {
    config.url = url
  }
  if (typeof window != 'undefined') {
    let apiUrl = new URL(config.url)
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
    config.url = apiUrl.href
  }
  const data = await axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const getContentFeedData = async (url = null) => {
  if (url != null) {
    contentFeedConfig.url = url
  }
  if (typeof window != 'undefined') {
    let apiUrl = new URL(contentFeedConfig.url)
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
    contentFeedConfig.url = apiUrl.href
  }
  const data = await axios(contentFeedConfig)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const getSuggestions = async (query) => {
  if (typeof window != 'undefined') {
    let apiUrl = new URL(suggestionConfig.url)
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
    suggestionConfig.url = apiUrl.href
  }
  suggestionConfig.url = suggestionConfig.url + `&query=${query}`
  const data = await axios(suggestionConfig)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const getBackgroundFilter = (params) => {
  if (params.slug.length > 1) {
    let slug = '/'
    let bgFilter = ''
    let count = 0
    if (params.slug.length == 2 && params.slug[1] == 'all') {
      slug = slug + params.slug[0] + '/'
      bgFilter = getCategoryNameByUrl(slug)
    } else {
      params.slug.map((item) => {
        count = count + 1
        slug = slug + item + '/'

        if (bgFilter == '') {
          bgFilter = getCategoryNameByUrl(slug) + '>'
        } else if (params.slug.length == count) {
          bgFilter = bgFilter + getCategoryNameByUrl(slug)
        } else {
          bgFilter = bgFilter + getCategoryNameByUrl(slug) + '>'
        }
      })
    }
    let returnVal = typeof bgFilter == 'string' && bgFilter != '' ? bgFilter.trim() : bgFilter
    return returnVal
  }
  return ''
}

export const getProductRecommendation = async (url) => {
  if (typeof window != 'undefined') {
    let apiUrl = new URL(url)
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
    url = apiUrl.href
  }
  const productRecommendation = await axios
    .get(url)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return productRecommendation
}

export const getTrendingSearch = async () => {
  const productRecommendation = await axios(trendingConfig)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return productRecommendation
}

export const getProduct3UpRecData = (productRec, baseConfigPath) => {
  const finalData = []
  productRec.map((item) => {
    let salePrice = 0
    let regPrice = 0
    let variantCollection = null
    let categoryName = null
    let imageUrl = 'https://dummyimage.com/600x400/000/fff'
    let noOfVariants = 0
    if (item.thumbnailImageUrl != undefined) {
      imageUrl = item.thumbnailImageUrl
    }
    if (item.ss_variants) {
      variantCollection = JSON.parse(item.ss_variants.replace(/&quot;/g, '"'))
      regPrice = variantCollection[0].price
      noOfVariants = variantCollection.length
    }
    let catName = ''
    if (item.categories) {
      if (item.categories.includes('Shop Men') && item.categories.includes('Shop Women')) {
        catName = ''
      } else if (item.categories.includes('Shop Men')) {
        catName = "Men's"
      } else if (item.categories.includes('Shop Women')) {
        catName = "Women's"
      }
      if (item.categories.includes('Multi Sport')) {
        catName = 'Multi Sport'
      } else {
        for (let cat in item.categories) {
          let val = item.categories[cat].includes('&amp;')
            ? item.categories[cat].replace('&amp;', '&')
            : item.categories[cat]
          let arr = ['Cycling', 'Running', 'Ski']
          if (arr.includes(val)) {
            if (catName == '') {
              catName = val
              break
            } else {
              catName = catName + ' ' + val
              break
            }
          }
        }
      }
    }
    categoryName = catName
    if (item.sale_price) {
      salePrice = item.sale_price
    }
    let proUrl = item.custom_url.slice(0, -1) + '-' + item.sku
    const tempProductData = {
      id: item.id,
      description: item.description,
      custom_fields: 'item.custom_fields',
      categories: item.categories,
      name: item.name,
      salePercentage: getDiscount(regPrice, salePrice),
      onSale: salePrice != '0' ? true : false,
      category: categoryName,
      salePrice: salePrice,
      regularPrice: regPrice,
      variants: noOfVariants,
      variantCollection: variantCollection,
      image: {
        url: item.cdn_standard_url[0],
      },
      images: item.cdn_standard_url[0],
      custom_url: proUrl,
      weight: item.weight,
      selectedVariant: null,
      sku: item.sku,
    }
    finalData.push(tempProductData)
  })
  return finalData
}

export const getSlugFromSearchSpringFilter = (searchSpringFilter) => {
  let formatedVal = searchSpringFilter.toLowerCase()
  formatedVal = formatedVal.replace(' ', '-')
  formatedVal = formatedVal.replace('>', '/')
  formatedVal = '/' + formatedVal + '/'
  return formatedVal
}

export const trackAddToCart = (productSkus, items, customerId) => {
  try {
    IntelliSuggest.init({
      siteId: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID,
      context: 'Basket',
      seed: productSkus,
    })
    IntelliSuggest.setShopperId(parseInt(customerId) == 0 ? '' : customerId)
    items.map((item) => {
      IntelliSuggest.haveItem({
        sku: item.sku,
        qty: item.qty,
        price: item.price,
        childSku: item.childSku,
      })
    })
    IntelliSuggest.inBasket({})
  } catch (err) {
    console.log('IntelliSuggest trackAddToCart', err)
  }
}

export const intellisuggestTrackClick = (elementHref, data, signature) => {
  var escapeFn = encodeURIComponent

  if (document.images) {
    if ('https:' == location.protocol) {
      var api_url = `https://${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}.a.searchspring.io/api/`
    } else {
      var api_url = `http://${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}.a.searchspring.io/api/`
    }

    var imgTag = new Image()
    imgTag.src =
      api_url +
      'track/track.json?d=' +
      data +
      '&s=' +
      signature +
      '&u=' +
      escapeFn(elementHref) +
      '&r=' +
      escapeFn(document.referrer)
  }

  return true
}

export const trackProductView = (product, customerId) => {
  try {
    var product_code = product.sku
    IntelliSuggest.init({
      siteId: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID,
      context: 'Product/' + product_code,
      seed: [product_code],
    })
    IntelliSuggest.setShopperId(parseInt(customerId) == 0 ? '' : customerId)
    IntelliSuggest.viewItem({ sku: product_code })
  } catch (err) {
    console.log('IntelliSuggest PDP track', err)
  }
}

export const getProductPopUpPosition = (item, componentName) => {
  let className = ''
  let productPopUpList = ''
  let minosIcon = ''
  switch (item) {
    case 'Hero Center Right':
      className = 'md:left-20 left-4'
      productPopUpList = 'left-0'
      minosIcon = 'justify-start'
      break
    case 'Hero Bottom Right':
      className = 'md:left-20 left-4'
      productPopUpList = 'left-0'
      minosIcon = 'justify-start'
      break
    case 'Hero PLP Bottom Spread':
      className = 'md:left-20 left-4'
      productPopUpList = 'left-0'
      minosIcon = 'justify-start'
      break
    default:
      className = 'md:right-20 right-4'
      productPopUpList = 'right-0'
      minosIcon = 'justify-end'
      break
  }
  if (componentName === 'heroModule') {
    return className
  } else if (componentName === 'productPopUpList') {
    return productPopUpList
  } else {
    return minosIcon
  }
}

export const getAutocompleteData = async (query, perPage = 1) => {
  // let autocompleteUrl = `https://${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}.a.searchspring.io/api/search/autocomplete.json?siteId=${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}&q=${query}&resultsFormat=native&redirectResponse=full`
  // let autocompleteUrl = url + `&q=${query}`
  let autocompleteUrl = new URL(
    `https://${process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID}.a.searchspring.io/api/search/autocomplete.json?resultsFormat=native&redirectResponse=full`
  )
  autocompleteUrl.searchParams.append('siteId', process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID)
  autocompleteUrl.searchParams.append('q', query)
  if (perPage != 1) {
    autocompleteUrl.searchParams.append('resultsPerPage', perPage)
  }
  config.url = autocompleteUrl.href
  if (typeof window != 'undefined') {
    let apiUrl = new URL(config.url)
    apiUrl.searchParams.append('userId', ssUserId)
    apiUrl.searchParams.append('sessionId', ssSessionIdNamespace)
    apiUrl.searchParams.append('pageLoadId', getPageLoadId())
    apiUrl.searchParams.append('domain', window.location.href)
    config.url = apiUrl.href
  }
  const data = await axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const getInlineBanners = async (data) => {
  try {
    if (data?.merchandising?.content?.inline?.length > 0) {
      let inGridIds = []
      let items = data?.merchandising?.content?.inline
      let begin = data?.pagination?.begin
      let end = data?.pagination?.end

      items?.forEach((item) => {
        let itemPartials = item?.value?.split('</script>')
        let position = item?.config?.position?.index || 0

        // check if contentful id exist and position in pagination range
        if (itemPartials[1] && position + 1 >= begin && position + 1 <= end) {
          let newPosition =
            data?.pagination?.currentPage == 1
              ? position
              : position - data?.pagination?.perPage * data?.pagination?.previousPage

          inGridIds.push({
            id: itemPartials[1]?.trim(),
            position: parseInt(newPosition < 0 ? 0 : newPosition),
          })
        }
      })

      if (inGridIds?.length > 0) {
        const response = await getInlineBannersData(inGridIds?.map((item) => item?.id)?.join(','))

        if (response?.success) {
          let gridItems = []

          response?.items?.forEach((item) => {
            let matchedItem = inGridIds?.find(
              (gridItem) => gridItem?.id == item?.fields?.entryTitle
            )
            let position = matchedItem?.position || 0

            gridItems.push({
              gridType: 'banner',
              ...item?.fields,
              position,
              promotionData: {
                promotion_id: null,
                promotion_name: 'Base | Static Page::Module | PLP Inline Banner',
                creative_name: item?.fields?.entryTitle || '',
                creative_slot: `PLP::${position + 1}`,
                location_id: position + 1,
              },
            })
          })

          //console.log('the grid items', gridItems)

          if (gridItems?.length > 0) {
            let resultArray = [...data?.results]?.map((item) => {
              return {
                gridType: 'product',
                ...item,
              }
            })

            gridItems.sort((a, b) => (a.position > b.position ? 1 : -1))

            gridItems?.forEach((item) => {
              if (item?.position == 0) {
                resultArray = [item, ...resultArray]
              } else if (item?.position > resultArray.length) {
                resultArray = [...resultArray, item]
              } else {
                resultArray = [
                  ...resultArray.slice(0, item?.position),
                  item,
                  ...resultArray.slice(item?.position, resultArray?.length),
                ]
              }
            })

            return resultArray
          }
        }
      }
    }

    return data?.results
  } catch (error) {
    console.log('getInlineBanners error', error)
    return data?.results
  }
}

export const getContentRecsData = async (tag) => {
  let urlObject = new URL(process.env.NEXT_PUBLIC_CONTENT_SEARCH_URL)
  let siteId = urlObject.searchParams.get('siteId')
  let recsUrl = `https://${siteId}.a.searchspring.io/boost/${siteId}/recommend`
  let recsWithTag = new URL(recsUrl)
  recsWithTag.searchParams.append('tags', tag)
  recsWithTag.searchParams.append('limits', 8)
  contentRecsConfig.url = recsWithTag.href
  const recsData = []
  const data = await axios(contentRecsConfig)
    .then(function (response) {
      let recsAttr = response?.data[0]?.results || []
      recsAttr.forEach((recs) => {
        let attr = recs.attributes
        let recsObj = {
          imgPath: attr?.image || '',
          excerpt: attr?.content || '',
          link: attr?.url || '',
          title: attr?.title || '',
          sys: {
            id: attr?.id || '',
            locale: 'en-US',
          },
          time: 'Read More',
          altTag: attr?.title || '',
        }
        recsData.push(recsObj)
      })
      return recsData
    })
    .catch(function (error) {
      console.log(error)
    })
  return data
}

export const parseResultData = (data) => {
  try {
    if (!data) {
      return null
    }

    const jsonString = data?.replace(/&quot;/g, '"')

    return JSON.parse(jsonString)
  } catch (error) {
    return null
  }
}
